/**
 * Created by think on 2021/6/21.
 */
export default {
    text(parentId){
        const userButtons = JSON.parse(sessionStorage.getItem('userButtons'));
        var ret = []
        userButtons.forEach(item => {
            if ( item.parentId == parentId) {
                ret.push(item)
            }
        })
        return ret
    }
}