<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="rolePermissionSearchFlag" @click="rolePermissionSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="rolePermissionAddFlag" @click="rolePermissionAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="rolePermissionModifyFlag" @click="rolePermissionModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="rolePermissionDelFlag" @click="rolePermissionDel">删除</el-button>
                        <el-button type="primary" plain size="mini" @click="rolePermissionAllTree">查看完整菜单树</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入菜单名称（模糊匹配）" v-model="AccurateAndUnique_menuName" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="菜单管理高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="菜单id" prop="menuId">
                                                    <el-input   v-model="dataForms.menuId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="父菜单id" prop="parentId">
                                                    <el-input   v-model="dataForms.parentId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="类型"  prop="menuType">
                                                    <el-select v-model="dataForms.menuType" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="菜单" value="1"></el-option>
                                                        <el-option label="页面" value="2"></el-option>
                                                        <el-option label="按钮" value="3"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="启用状态" prop="status">
                                                    <el-select v-model="dataForms.status" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="启用" value="1"></el-option>
                                                        <el-option label="冻结" value="0"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <el-button size="mini" v-if="rolePermissionSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog
                :title="dialogrolePermissionstitile"
                :visible.sync="dialog_rolePermissions"
                width="75%"
                :modal="true"
                center
                :before-close="handleClose_rolePermissions">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">菜单信息</span>
                </div>
                <div>
                    <el-form :model="rolePermissionsForm"  ref="rolePermissionsForm" :rules="rolePermissionsFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="菜单ID" prop="menuId">
                                    <el-input v-model="rolePermissionsForm.menuId" :disabled="rolePermissionsFlag[0]" style="width: 193px"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="菜单名称" prop="menuName">
                                    <el-input v-model="rolePermissionsForm.menuName" :disabled="rolePermissionsFlag[1]" style="width: 193px"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="菜单层级" prop="level">
                                    <el-input v-model="rolePermissionsForm.level" style="width: 193px"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="页面url" prop="path">
                                    <el-input v-model="rolePermissionsForm.path" style="width: 193px"></el-input>
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="6">-->
                                <!--<el-form-item label="图标url" prop="icon">-->
                                    <!--<el-input v-model="rolePermissionsForm.icon" style="width: 193px"></el-input>-->
                                <!--</el-form-item>-->
                            <!--</el-col>-->
                            <el-col :span="8">
                                <el-form-item label="父菜单ID" prop="parentId">
                                    <el-input v-model="rolePermissionsForm.parentId" style="width: 193px"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="排序" prop="sort">
                                    <el-input v-model="rolePermissionsForm.sort" style="width: 193px"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="状态" prop="status">
                                    <el-select v-model="rolePermissionsForm.status" placeholder="请选择状态">
                                        <el-option label="启用" value="1"></el-option>
                                        <el-option label="冻结" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="页面类型" prop="menuType">
                                    <el-select v-model="rolePermissionsForm.menuType" placeholder="请选择页面类型">
                                        <el-option label="菜单" value="1"></el-option>
                                        <el-option label="页面" value="2"></el-option>
                                        <el-option label="按钮" value="3"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="是否父节点" prop="parent">
                                    <el-select v-model="rolePermissionsForm.parent" placeholder="请选择是否">
                                        <el-option label="是" value="1"></el-option>
                                        <el-option label="否" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_rolePermissions">提 交</el-button>
                <el-button @click="handleClose_rolePermissions">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import axios from 'axios'
    import arrayQuery from '../../components/arrayQuery.js'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialogrolePermissionstitile:'',
                dialog_rolePermissions:false,
                rolePermissionsFlag:[false,false],
                AccurateAndUnique_menuName:'',
                dataForms:{
                    menuId:'',
                    menuName:'',
                    parentId:'',
                    menuType:'',
                    status:''
                },
                rolePermissionsForm:{
                    id:'',
                    menuId:'',
                    menuName:'',
                    level:'',
                    path:'',
                    icon:'',
                    parentId:'',
                    menuType:'',
                    sort:'',
                    status:'',
                    parent:''
                },
                rolePermissionsFormrules:{
                    menuId: [
                        { required: true, message: '请输入菜单id', trigger: 'blur' }
                    ],
                    menuName: [
                        { required: true,message: '请输入菜单名称', trigger: 'blur' }
                    ],
                    level: [
                        { required: true,message: '请输入菜单层级', trigger: 'blur' }
                    ],
                    parentId: [
                        { required: true, message: '请输入菜单父ID', trigger: 'blur' }
                    ],
                    menuType: [
                        { required: true, message: '请输入菜单类型', trigger: 'blur' }
                    ],
                    sort: [
                        { required: true, message: '请输入菜单排序', trigger: 'blur' }
                    ],
                    status: [
                        { required: true, message: '请输入菜单状态', trigger: 'blur' }
                    ],
                    parent: [
                        { required: true, message: '请明确菜单是否父节点', trigger: 'blur' }
                    ]
                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'菜单id',prop:'menuId',width:'120',align:'center'},
                    {label:'菜单名称',prop:'menuName',width:'160',align:'center'},
                    {label:'菜单层级',prop:'level',align:'center'},
                    {label:'页面地址url',prop:'path',align:'center',width:'160'},
                    {label:'图标url',prop:'icon',align:'center',width:'160'},
                    {label:'父菜单id',prop:'parentId',align:'center'},
                    {label:'排序',prop:'sort',align:'center',width:'120'},
                    {label:'类型',prop:'menuType',align:'center',
                        formatter: (row) => {
                            if (row.menuType == '1') {
                                return "菜单";
                            } else if (row.menuType == '2') {
                                return "页面";
                            } else if (row.menuType == '3') {
                                return "按钮";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'启用状态',prop:'status',align:'center',
                        formatter: (row) => {
                            if (row.status == '1') {
                                return "启用";
                            } else if (row.status == '0') {
                                return "冻结";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'是否父节点',prop:'parent',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.companyType == '1') {
                                return "是";
                            } else if (row.companyType == '0') {
                                return "否";
                            } else {
                                return "";
                            }
                        }
                    }
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                rolePermissionSearchFlag:true,
                rolePermissionAddFlag:true,
                rolePermissionModifyFlag:true,
                rolePermissionDelFlag:true
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            Drawer
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '1_3';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let rolePermissionSearch = currentPageButtons.find(item => {
                return item.menuId == '1_3_1';
            });
            if(rolePermissionSearch == undefined){
                _this.rolePermissionSearchFlag = false;
            }else {
                _this.rolePermissionSearchFlag = true;
            }
            //新增按钮权限
            let rolePermissionAdd = currentPageButtons.find(item => {
                return item.menuId == '1_3_2';
            });
            if(rolePermissionAdd == undefined){
                _this.rolePermissionAddFlag = false;
            }else {
                _this.rolePermissionAddFlag = true;
            }
            //修改按钮权限
            let rolePermissionModify = currentPageButtons.find(item => {
                return item.menuId == '1_3_3';
            });
            if(rolePermissionModify == undefined){
                _this.rolePermissionModifyFlag = false;
            }else {
                _this.rolePermissionModifyFlag = true;
            }
            //删除按钮权限
            let rolePermissionDel = currentPageButtons.find(item => {
                return item.menuId == '1_3_4';
            });
            if(rolePermissionDel == undefined){
                _this.rolePermissionDelFlag = false;
            }else {
                _this.rolePermissionDelFlag = true;
            }
        },
        methods:{
            handleClose_rolePermissions(){
                this.dialog_rolePermissions = false;
                this.$refs.rolePermissionsForm.resetFields();
            },
            save_rolePermissions(){
                this.$refs["rolePermissionsForm"].validate(valid => {
                    if (valid) {
                        const data = new URLSearchParams();
                        data.append('id', this.rolePermissionsForm.id);
                        data.append('menuId', this.rolePermissionsForm.menuId);
                        data.append('menuName', this.rolePermissionsForm.menuName);
                        data.append('level', this.rolePermissionsForm.level);
                        data.append('path', this.rolePermissionsForm.path);
                        data.append('icon', this.rolePermissionsForm.icon);
                        data.append('parentId', this.rolePermissionsForm.parentId);
                        data.append('menuType', this.rolePermissionsForm.menuType);
                        data.append('status', this.rolePermissionsForm.status);
                        data.append('sort', this.rolePermissionsForm.sort);
                        data.append('parent', this.rolePermissionsForm.parent);
                        if(this.dialogrolePermissionstitile == '新增菜单'){
                            //新增
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/menu/addMenu`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '新增成功',
                                        type: 'success'
                                    });
                                    this.handleClose_rolePermissions();
                                    this.rolePermissionSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_rolePermissions();
                                }
                            });
                        }else if(this.dialogrolePermissionstitile == '修改菜单'){
                            //修改
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/menu/updateMenu`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });
                                    this.handleClose_rolePermissions();
                                    this.rolePermissionSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_rolePermissions();
                                }
                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.rolePermissionSearch();
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.rolePermissionSearch()
            },
            handleQuery(){ //查询
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                //快捷查询
                this.loading = true;
                const data = new URLSearchParams();
                data.append('menuName', this.AccurateAndUnique_menuName);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/menu/queryMenusPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //查询
            rolePermissionSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('menuId', this.dataForms.menuId);
                data.append('menuName', this.dataForms.menuName);
                data.append('parentId', this.dataForms.parentId);
                data.append('menuType', this.dataForms.menuType);
                data.append('status', this.dataForms.status);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/menu/queryMenusPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            rolePermissionSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.rolePermissionSearch();
            },
            //新增
            rolePermissionAdd(){
                this.dialogrolePermissionstitile = '新增菜单';
                this.rolePermissionsFlag = [false,false];
                this.dialog_rolePermissions = true;
            },
            //修改
            rolePermissionModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '更新操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.dialogrolePermissionstitile = '修改菜单';
                    this.rolePermissionsFlag = [true,true];
                    this.dialog_rolePermissions = true;
                    axios.get(`${this.$apiPath}/api/pm/menu/queryMenuById/`+ this.merge[0].id).then(res=>{
                        if(res.data.data.status == true){
                            res.data.data.status = '1'
                        }else if(res.data.data.status == false){
                            res.data.data.status = '0'
                        }
                        if(res.data.data.parent == true){
                            res.data.data.parent = '1'
                        }else if(res.data.data.parent == false){
                            res.data.data.parent = '0'
                        }
                        res.data.data.menuType = JSON.stringify(res.data.data.menuType)
                        this.rolePermissionsForm = res.data.data;
                    })
                }
            },
            //删除
            rolePermissionDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对菜单进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/pm/menu/deleteMenu/`+ this.merge[0].menuId).then(res=>{

                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.rolePermissionSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            //查看完整菜单树
            rolePermissionAllTree(){
                this.$router.replace({ path: "/rolePermissionAllTree" });
            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.rolePermissionSearch_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>

    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
